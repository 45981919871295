@font-face {
  font-family: "pina";
  src: url("./pina.eot?v9kbic");
  src: url("./pina.eot?v9kbic#iefix") format("embedded-opentype"),
    url("./pina.ttf?v9kbic") format("truetype"),
    url("./pina.woff?v9kbic") format("woff"),
    url("./pina.svg?v9kbic#pina") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* 
  feathericons
  https://feathericons.com/
*/
[class^="fe-"],
[class*=" fe-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "pina" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
