//
// type.scss
//

// Import DM Sans & DM Serif Display

// Import Feather icons

@font-face {
  font-family: 'Feather';
  src: url('#{$path-to-fonts}/feather/Feather.ttf?sdxovp') format('truetype'), url('#{$path-to-fonts}/feather/Feather.woff?sdxovp') format('woff'), url('#{$path-to-fonts}/feather/Feather.svg?sdxovp#Feather') format('svg');
  font-weight: 400;
  font-style: normal;
}

// Import DM Sans & DM Serif Locally 
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{$path-to-fonts}/DM Sans/DM Sans latin-ext400.woff2?sdxovp') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{$path-to-fonts}/DM Sans/DM Sans latin400.woff2?sdxovp') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('#{$path-to-fonts}/DM Sans/DM Sans latin-ext500.woff2?sdxovp') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('#{$path-to-fonts}/DM Sans/DM Sans latin500.woff2?sdxovp') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('#{$path-to-fonts}/DM Sans/DM Sans latin-ext700.woff2?sdxovp') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('#{$path-to-fonts}/DM Sans/DM Sans latin700.woff2?sdxovp') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{$path-to-fonts}/DM Serif/DM Serif Display latin-ext.woff2?sdxovp') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{$path-to-fonts}/DM Serif/DM Serif Display latin.woff2?sdxovp') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


// Responsive fonts

@include media-breakpoint-down(md) {

  h1, .h1 {
    font-size: $h1-font-size-sm;
  }

  h2, .h2 {
    font-size: $h2-font-size-sm;
  }

  h3, .h3 {
    font-size: $h3-font-size-sm;
  }

  .display-4 {
    font-size: $display4-size-sm;
  }

  .display-3 {
    font-size: $display3-size-sm;
  }

  .display-2 {
    font-size: $display2-size-sm;
  }

  .display-1 {
    font-size: $display1-size-sm;
  }

  .lead {
    font-size: $lead-font-size-sm;
  }
}


// Headings

h1, .h1, h2, .h2 {
  line-height: $h1-line-height;
}

h2, .h3 {
  line-height: $h3-line-height;
}

h4, .h4, h5, .h5, h6, .h6 {
  line-height: $h4-line-height;
}

h6.text-uppercase, .h6.text-uppercase {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-bolder;
  letter-spacing: .18em;
}


// Sans serif

.display-1.font-sans-serif, .display-2.font-sans-serif, .display-3.font-sans-serif, .display-4.font-sans-serif {
  letter-spacing: -.02em;
}

h1.font-sans-serif, .h1.font-sans-serif {
  letter-spacing: -.02em;
}

h2.font-sans-serif, .h2.font-sans-serif, h3.font-sans-serif, .h3.font-sans-serif, h4.font-sans-serif, .h4.font-sans-serif {
  letter-spacing: -.01em;
}



// List checked

.list-checked {
  padding-left: 0;
  text-align: left;
  list-style-type: none;
}

.list-checked > li {
  position: relative;
  padding-left: 2.25rem;
}

.list-checked > li + li {
  margin-top: .75rem;
}

.list-checked > li::before {
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Feather";
  content: "\e92b";
}

@each $color, $value in $theme-colors {
  .list-checked-#{$color} > li::before {
    color: $value;
  }
}

// Circle

.list-checked-circle > li::before {
  top: calc((#{$line-height-base} * 1rem - 1.25rem));
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  font-size: $font-size-xxs;
  border-radius: 50%;
}

@each $color, $value in $theme-colors {
  .list-checked-circle.list-checked-#{$color} > li::before {
    background-color: $value;
    color: color-contrast($value);
  }
}



// Divider

.hr-sm {
  max-width: 5rem;
  margin-top: 0;
  
  &:not([size]) {
    height: $border-width * 5;
  }
}


// Strong

strong {
  font-weight: $font-weight-bold;
}


// List

ul, ol {
  margin-bottom: $paragraph-margin-bottom;
}


// Links

a, button {
  outline: none !important;
}
