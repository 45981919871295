// 
// user.scss
// Use this to write your custom SCSS
//
.filepond--root {
  overflow: hidden;
  border-top-left-radius: $border-radius-sm;
  border-bottom-right-radius: $border-radius-sm;
  min-height: 150px;
  background-color: $gray-200;
  
  .filepond--drop-label {
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: $gray-600;
    padding-bottom: 0.75rem;
    padding: 0;
    background-color: $gray-200;
  }
  
  .filepond--drop-label.filepond--drop-label label {
    padding: 0.75rem 1.25rem;
    font-size: 18px;
    text-align: start;
  }
}

.form-control, .form-select {
  background-color: $gray-200;

  &:focus {
    background-color: $gray-200;
    border-bottom: 3px solid $gray-400;
    margin-bottom: -2px;
  }
}

.form-check-input {
  background-size: 65% !important;
  background-color: $gray-200;
  padding: 12px;
  border: 1px solid $gray-200;
  cursor: pointer;
  margin-right: 0.5em;
  
  &[type="radio"] {
    border-radius: 50% !important;
  }
  
  &:focus {
    background-color: $gray-200;
    border-color: $gray-200;
    box-shadow: none;
    margin-bottom: 0 !important;
    border-bottom: 1px;
  }
  
  &:checked {
    background-color: $primary;
    border-color: transparent;
  }
}

.form-check-label {
  display: inline-flex;
  margin-top: 0.25rem;
  margin-bottom: 1em;
  margin-left: 0.5rem;
}

.navbar {
  .dropdown {
    .dropdown-menu {
      // inset: auto !important;
      top: auto !important;
    }

    &.show {
      .dropdown-menu {
        transform: translate3d(0px, 0px, 0px) !important;
      }
    }

    .dropdown-item {
      color: $primary;
    }
  }
}

.flex-1 {
  flex: 1;
}

// overwrite cookie consent styles
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll, #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
  background-color: $primary !important;
  border-color: $primary !important;
  color: $white !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection, #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border-color: $primary  !important;
  color: $primary  !important;
}

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a, #CookiebotWidget .CookiebotWidget-consent-details button {
  color: $primary !important;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
  fill: $primary !important;
}

#CybotCookiebotDialogTabContent .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: $primary-disabled !important;
}

#CybotCookiebotDialogTabContent input:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: $primary !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 1px solid $primary !important;
  color: $primary !important;
}

#CookiebotWidget .CookiebotWidget-logo svg path {
  fill: $primary  !important;
}

#CookiebotWidget .CookiebotWidget-logo svg circle {
  fill: $white !important;
}

.text-muted a :not(.dropdown-item){
  text-decoration: underline;
  color: inherit;
}

