//
// grid.scss
//

// Negative offset

@for $i from 1 through $grid-columns {
  .offset-n#{$i} {
    margin-left: -(divide(100%, $grid-columns) * $i);
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  @if $breakpoint !='xs' {
    @include media-breakpoint-up(#{$breakpoint}) {
      @for $i from 1 through $grid-columns {
        .offset-#{$breakpoint}-n#{$i} {
          margin-left: -(divide(100%, $grid-columns) * $i);
        }
      }
    }
  }
}


// Container

.container-lg {

  @include media-breakpoint-only(sm) {
    max-width: 90%;
  }

  @include media-breakpoint-only(md) {
    max-width: 95%;
  }
}


// Cols

[class*="col"] {
  position: relative;
}